import { Button, Icon, IconButton } from "@mui/material";
    import useAlert from "hooks/useAlert";
    import useSnackbar from "hooks/useSnackbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { adminList, adminsDelete, adminsUpdateStatus } from "services/api/admin";
import { setAdminList } from "store/admin/admin.action";
import { selectAdmin } from "store/admin/admin.selector";
import SimpleSwitch from "ui-component/SimpleSwitch";
import Datatable from "ui-component/tables/Datatable";
import { isObjectEmpty } from "utils/helper";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from "react-router-dom";


const Admin = () => {

    const title = 'admin';

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { updateState } = useAlert();

    const { openSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);

        try {
            adminList().then((res) => {
                dispatch(setAdminList(res.data.result.data));
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
        }
    }, [])

    const deleteHandler = (id) => {
        try {
            adminsDelete(id).then((res) => {
                adminList().then((res) => {
                    dispatch(setAdminList(res.data.result.data));
                });
                openSnackbar("success", res.data.message)
            }).catch((error) => {
                openSnackbar("error", error.data.message)
            });
        } catch (e) {
            // console.log(e)
        }
    }

    const handleEdit = (event, row) => {
        const url = '/admins/edit/' + row.rowData[0];
        navigate(url);

    }

    const handleStatusUpdate = (id, value) => {
        adminsUpdateStatus(id).then((res) => {
            adminList().then((res) => {
                dispatch(setAdminList(res.data.result.data));
            });
            openSnackbar("success", res.data.message)
        }).catch((error) => {
            openSnackbar("error", error.data.message)
        });
    }

    const handleDelete = (event, row) => {
        updateState('Admin Delete', () => deleteHandler(row['rowData'][0]));
    }

    const admins = useSelector(selectAdmin);

    const columns = [
        {
            name: "id",
            label: "id",
            options: {
                display: false,
                download: false,
                filter: false,
                sort: false,
            }
        },
        {
            name: "Sr.no.",
            options: {
                display: true,
                download: true,
                filter: false,
                sort: false,
            }
        },
        {
            name: "name",
            label: "Name",
        },
        {
            name: "email",
            label: "Email"
        },
        {
            name: "is_active",
            label: "Status",
            options: {
                customBodyRender: (valueData, tableMeta) => {
                    let value = (valueData == 'Active') ? 1 : 0;
                    return (
                        <SimpleSwitch id={tableMeta['rowData'][0]} value={value} changeHandler={(id, value) => handleStatusUpdate(id, value)} />
                    );
                },
                filter: true,
                filterType: 'dropdown'
            }

        },
        {
            label: "Create Date",
            options: {
                filter: false,
            }
        },
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <IconButton onClick={(e) => { handleEdit(e, tableMeta) }}>
                                <EditIcon color="primary" />
                            </IconButton>
                            <IconButton onClick={(e) => { handleDelete(e, tableMeta) }}>
                                <DeleteIcon color="secondary" />
                            </IconButton>
                        </>
                    );
                }
            }
        }
    ];

    const data = isObjectEmpty(admins) ? [] : admins.map((item, index) => {
        return [
            item.id,
            index += 1,
            item.name,
            item.email,
            (item.is_active == 1) ? 'Active' : 'Inactive',
            item.created_at,
        ]
    })


    const options = {
        filterType: 'textField',
        fixedHeader: true,
        tableBodyHeight: "400px"
    }

    return (
        <Datatable title={title} data={data} columns={columns} options={options} isLoading={loading} addHandler={() => navigate("/admins/create")} />
    );
};

export default Admin;
