import { useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import AdminRoutes from "views/admin/AdminRoutes";
import BannerRoutes from "views/banner/BannerRoutes";
import CategoryRoutes from "views/category/CategoryRoutes";
import CmsRoutes from "views/cms/CmsRoutes";
import SubCategoryRoutes from "views/sub-category/SubCategoryRoutes";
import ColorRoute from "views/color/ColorRoute";
import ProductRoutes from "views/product/ProductRoutes";
import UserProductViewRoute from "views/user-product-view/UserProductViewRoute";
import ContactUsRoutes from "views/contact-us/ContactUsRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    MainRoutes,
    AuthenticationRoutes,
    AdminRoutes,
    BannerRoutes,
    CategoryRoutes,
    SubCategoryRoutes,
    CmsRoutes,
    ColorRoute,
    ProductRoutes,
    UserProductViewRoute,
    ContactUsRoutes,
  ]);
}
