import bannerManagement from './bannerManagement';
import cmsManagement from './cmsManagement';
import dashboard from './dashboard';
import productManagement from './productManagement';
import userManagement from './userManagement';
import userProductView from './userProductView';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, userManagement, productManagement, cmsManagement,userProductView]
};

export default menuItems;
