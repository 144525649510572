import { IconButton } from "@mui/material";
import useSnackbar from "hooks/useSnackbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { colorDelete, colorList, colorUpdateStatus } from "services/api/color";
import { setColorList } from "store/color/color.action";
import { selectColor } from "store/color/color.selector";
import SimpleSwitch from "ui-component/SimpleSwitch";
import Datatable from "ui-component/tables/Datatable";
import { isObjectEmpty } from "utils/helper";

import useAlert from "hooks/useAlert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
const Color = () => {
  const title = "Color";

  const [loading, setLoading] = useState(false);
  const [operation, setOperation] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { updateState } = useAlert();
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    try {
      colorList().then((res) => {
        dispatch(setColorList(res.data.result.data));
      });
      console.log(operation);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [operation]);

  const handleStatusUpdate = (id, value) => {
    try {
      colorUpdateStatus(id).then((res) => {
        setOperation(!operation);
      });
    } catch (error) {
      openSnackbar("error", error.data.message);
    }
  };

  const options = {
    filterType: "textField",
    fixedHeader: true,
    tableBodyHeight: "400px",
  };
  const handleEdit = (event, row) => {
    navigate("/colors/update/" + row.rowData[0]);
  };
  const handleDelete = (event, row) => {
    updateState("Color Delete", () => deleteHandler(row["rowData"][0]));
  };

  const deleteHandler = (id) => {
    setLoading(true);

    // try {
    //   categoryDelete(id).then((res) => {
    //     categoryList(1).then((res) => {
    //       dispatch(setSubCategoryList(res.data.result.data));
    //     });
    //     openSnackbar("success", res.data.message)
    //   }).catch((error) => {
    //     openSnackbar("error", error.data.message)
    //   });
    // } catch (e) {
    //   // console.log(e)
    // }


    try {
      colorDelete(id).then((res) => {
        colorList(1).then((res) => {
          dispatch(setColorList(res.data.result.data));
        })
        openSnackbar("success", res.data.message)
        setOperation(!operation);
      }).catch((error) => {
        openSnackbar("error", error.response.data.message)
      });
    } catch (error) { }
  };
  const colors = useSelector(selectColor);

  const data = isObjectEmpty(colors)
    ? []
    : colors.map((item, index) => {
      return [
        item.id,
        (index += 1),
        item.arabic_name,
        item.english_name,
        item.is_active,
        item.created_at,
      ];
    });
  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
        filter: false,
        sort: false,
        download: true,
      },
    },
    {
      name: "Sr No.",
      options: {
        filter: false,
        sort: false,
        download: true,
      },
    },
    {
      name: "arabic_name",
      label: "Arabic Name",
    },
    {
      name: "english_name",
      label: "English Name",
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        customBodyRender: (valueData, tableMeta) => {
          return (
            <SimpleSwitch
              id={tableMeta["rowData"][0]}
              value={valueData}
              changeHandler={(id, value) => handleStatusUpdate(id, value)}
            />
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Created Date",
    },
    {
      name: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <IconButton
                onClick={(e) => {
                  handleEdit(e, tableMeta);
                }}
              >
                <EditIcon color="primary" />
              </IconButton>

              <IconButton
                onClick={(e) => {
                  handleDelete(e, tableMeta);
                }}
              >
                <DeleteIcon color="secondary" />
              </IconButton>
            </>
          );
        },
      },
    },
  ];
  return (
    <Datatable
      title={title}
      data={data}
      columns={columns}
      options={options}
      isLoading={loading}
      addHandler={() => navigate("/colors/create")}
    />
  );
};
export default Color;
