import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'auth/AuthGuard'; 
import ContactUs from './ContactUs';

// ==============================|| MAIN ROUTING ||============================== //

const ContactUsRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/contact-us',
            element: <ContactUs/>
        }
    ]
};

export default ContactUsRoutes;
