import { Avatar, IconButton } from "@mui/material";
import useAlert from "hooks/useAlert";
import useSnackbar from "hooks/useSnackbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import SimpleSwitch from "ui-component/SimpleSwitch";
import Datatable from "ui-component/tables/Datatable";
import { isObjectEmpty } from "utils/helper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  categoryDelete,
  categoryList,
  categoryUpdateStatus,
} from "services/api/category";
import { setCategoryList } from "store/category/category.action";
import { selectCategory } from "store/category/category.selector";

const Category = () => {
  const title = "category";

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { updateState } = useAlert();

  const { openSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    try {
      categoryList(0).then((res) => {
        dispatch(setCategoryList(res.data.result.data));
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const deleteHandler = (id) => {
    try {
      categoryDelete(id)
        .then((res) => {
          categoryList(0).then((res) => {
            dispatch(setCategoryList(res.data.result.data));
          });
          openSnackbar("success", res.data.message);
        })
        .catch((error) => {
          openSnackbar("error", error.response.data.message);
        });
    } catch (e) {
      // console.log(e)
    }
  };

  const handleEdit = (event, row) => {
    const url = "/categories/edit/" + row.rowData[0];
    navigate(url);
  };

  const handleStatusUpdate = (id, value) => {
    categoryUpdateStatus(id)
      .then((res) => {
        categoryList(0).then((res) => {
          dispatch(setCategoryList(res.data.result.data));
        });
        openSnackbar("success", res.data.message);
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      });
  };

  const handleDelete = (event, row) => {
    updateState("Category Delete", () => deleteHandler(row["rowData"][0]));
  };

  const categories = useSelector(selectCategory);

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "Sr.no.",
      options: {
        display: true,
        download: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: "arabic_name",
      label: "Name In Arabic ",
    },
    {
      name: "english_name",
      label: "Name In English ",
    },
    {
      name: "arabic_image",
      label: "Image In Arabic ",
      options: {
        customBodyRender: (valueData, tableMeta) => {
          return (
            <a href={valueData} target="_blank">
              <Avatar
                alt="Category image"
                src={valueData}
                variant="square"
                sx={{ width: 50, height: 50 }}
              />
            </a>
          );
        },
        filter: false,
      },
    },
    {
      name: "english_image",
      label: "Image In English ",
      options: {
        customBodyRender: (valueData, tableMeta) => {
          return (
            <a href={valueData} target="_blank">
              <Avatar
                alt="Category image"
                src={valueData}
                variant="square"
                sx={{ width: 50, height: 50 }}
              />
            </a>
          );
        },
        filter: false,
      },
    },
    // {
    //     name: "is_active",
    //     label: "Status",
    //     options: {
    //         customBodyRender: (valueData, tableMeta) => {
    //             let value = (valueData == 'Active') ? 1 : 0;
    //             return (
    //                 <SimpleSwitch id={tableMeta['rowData'][0]} value={value} changeHandler={(id, value) => handleStatusUpdate(id, value)} />
    //             );
    //         },
    //         filter: true,
    //         filterType: 'dropdown'
    //     }

    // },
    {
      label: "Create Date",
      options: {
        filter: false,
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <IconButton
                onClick={(e) => {
                  handleEdit(e, tableMeta);
                }}
              >
                <EditIcon color="primary" />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  handleDelete(e, tableMeta);
                }}
              >
                <DeleteIcon color="secondary" />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const data = isObjectEmpty(categories)
    ? []
    : categories.map((item, index) => {
        return [
          item.id,
          (index += 1),
          item.arabic_name,
          item.english_name,
          item.arabic_image,
          item.english_image,
          // (item.is_active == 1) ? 'Active' : 'Inactive',
          item.created_at,
        ];
      });

  const options = {
    filterType: "textField",
    fixedHeader: true,
    tableBodyHeight: "400px",
  };

  return (
    <Datatable
      title={title}
      data={data}
      columns={columns}
      options={options}
      isLoading={loading}
      addHandler={() => navigate("/categories/create")}
    />
  );
};

export default Category;
