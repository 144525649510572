import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./customizationReducer";
import { AdminReducer } from "./admin/admin.reducer";
import { BannerReducer } from "./banner/banner.reducer";
import { CategoryReducer } from "./category/category.reducer";

import { CmsReducer } from "./cms/cms.reducer";
import { ColorReducer } from "./color/color.reducer";
import { ProductReducer } from "./product/product.reducer";
import { UserProductViewReducer } from "./userProductView/userProductView.reducer";
import { ContactUsReducer } from "./contactUs/contactUs.reducer";
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  admin: AdminReducer,
  banner: BannerReducer,
  category: CategoryReducer,
  cms: CmsReducer,
  color: ColorReducer,
  product: ProductReducer, 
  userProductView:UserProductViewReducer,
  contactUs:ContactUsReducer,
});

export default reducer;
