import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/helper";
import Breadcrumb from "ui-component/Breadcrumb";
import SimpleCard from "ui-component/SimpleCard";
import { selectCms } from "store/cms/cms.selector";
import { cmsList, cmsUpdate } from "services/api/cms";
import { setCmsList } from "store/cms/cms.action";
import CmsForm from "./CmsForm";
import { useEffect, useState } from "react";
import Loader from "ui-component/Loader";

const Cms = () => {
    const title = 'cms';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const dispatch = useDispatch();
    const { cmsId } = useParams();
    const [loading, setLoading] = useState();

    
    useEffect(() => {
        setLoading(true);
        
        try {
            cmsList().then((res) => {
                dispatch(setCmsList(res.data.result.data));
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
        }
    }, [])
    
    const cmsState = useSelector(selectCms);

    const currentCms = cmsState.filter(item => item.id == cmsId);
    
    const handleSubmit = (state) => {

        const formData = {
            "english_title": state.english_title,
            "arabic_title": state.arabic_title,
            "english_content": state.english_content,
            "arabic_content": state.arabic_content,
            "file":state.file,
        }

        return cmsUpdate(cmsId, formData);
    };


    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Cms Update" }]} />
            </Box>
            <SimpleCard title="Cms Form">
                {loading ? <Loader/> : <CmsForm handleSubmit={handleSubmit} tableData={{ ...currentCms[0] }} id={cmsId} />}
            </SimpleCard>
        </Container>
    );
};

export default Cms;
