import { LoadingButton } from "@mui/lab";
import { Grid, TextField } from "@mui/material";
import { Formik } from "formik";
import useSnackbar from "hooks/useSnackbar";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { contactUsList } from "services/api/contact-us";
import { setContactUsList } from "store/contactUs/contactUs.action";
import FileInput from "ui-component/FileInput";
import QuillTextEditorWrapper from "ui-component/QuillTextEditorWrapper";

const ContactUsForm = ({ handleSubmit, tableData, id }) => {
  const [state, setState] = useState({ ...tableData });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();

  const handleFormSubmit = async (values, { setFieldError }) => {
    setLoading(true);
    await handleSubmit({
      ...values,
    })
      .then((res) => {
        openSnackbar("success", res.data.message);
        dispatch(setContactUsList(res.data.result));
        setLoading(false);
        // navigate(-1);
      })
      .catch((e) => {
        if (e.response.status == 422) {
          Object.entries(e.response.data.result).forEach(([key, value]) =>
            setFieldError(key, value[0])
          );
        }
        setLoading(false);
      });
  };
  const handleChange = (event) => {
    event.persist();
    setState({ ...state, [event.target.name]: event.target.value });
    // console.log(state);
  };
  return (
    <div>
      <Formik onSubmit={handleFormSubmit} initialValues={state}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={6}>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="email"
                  id="email"
                  value={values.email || ""}
                  onChange={handleChange}
                  label="Email"
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="phone_no"
                  id="phone_no"
                  value={values.phone_no || ""}
                  onChange={handleChange}
                  label="Phone No."
                  helperText={touched.phone_no && errors.phone_no}
                  error={Boolean(touched.phone_no && errors.phone_no)}
                  fullWidth
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="instagram"
                  id="instagram"
                  value={values.instagram || ""}
                  onChange={handleChange}
                  label="Instagram"
                  helperText={touched.instagram && errors.instagram}
                  error={Boolean(touched.instagram && errors.instagram)}
                  fullWidth
                />
              </Grid>
              {/* <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="x"
                  id="x"
                  value={values.x || ""}
                  onChange={handleChange}
                  label="x"
                  helperText={touched.x && errors.x}
                  error={Boolean(touched.x && errors.x)}
                  fullWidth
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="facebook"
                  id="facebook"
                  value={values.facebook || ""}
                  onChange={handleChange}
                  label="facebook"
                  helperText={touched.facebook && errors.facebook}
                  error={Boolean(touched.facebook && errors.facebook)}
                  fullWidth
                />
              </Grid> */}
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="website"
                  id="website"
                  value={values.website || ""}
                  onChange={handleChange}
                  label="Website"
                  helperText={touched.website && errors.website}
                  error={Boolean(touched.website && errors.website)}
                  fullWidth
                />
              </Grid>
              {/* <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="location"
                  id="location"
                  value={values.location || ""}
                  onChange={handleChange}
                  label="Location"
                  helperText={touched.location && errors.location}
                  error={Boolean(touched.location && errors.location)}
                  fullWidth
                />
              </Grid> */}
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="location_url"
                  id="location_url"
                  value={values.location_url || ""}
                  onChange={handleChange}
                  label="Location Url"
                  helperText={touched.location_url && errors.location_url}
                  error={Boolean(touched.location_url && errors.location_url)}
                  fullWidth
                />
              </Grid>
            </Grid>

            <LoadingButton
              type="submit"
              color="primary"
              loading={loading}
              variant="contained"
              sx={{ my: 4 }}
            >
              Submit
            </LoadingButton>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ContactUsForm;
