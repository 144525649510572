import { LoadingButton } from "@mui/lab";
import * as Yup from 'yup';
import {
    Autocomplete,
    Grid,
    TextField,
} from "@mui/material";
import { Formik } from "formik";
import useSnackbar from "hooks/useSnackbar";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCategory } from "store/category/category.selector";
import FileInput from "ui-component/FileInput";


const SubCategoryForm = ({ handleSubmit, tableData }) => {
    const [state, setState] = useState({ ...tableData });

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { openSnackbar } = useSnackbar();
    const categories = useSelector(selectCategory);


    const handleChange = (event) => {
        event.persist();
        setState({ ...state, [event.target.name]: event.target.value });
        // console.log(state);
    };

    const handleFileChange = (index, file) => {
        console.log(index, file);
        setState({ ...state, [file.id]: file.files[0] });
    };


    const handleFormSubmit = async (values, { setFieldError }) => {
        setLoading(true);
        await handleSubmit({ ...values, arabic_image: state.arabic_image, english_image: state.english_image }).then((res) => {
            openSnackbar("success", res.data.message)
            setLoading(false);
            navigate("/sub-categories");
        }).catch((e) => {
            if (e.response.status == 422) {
                Object.entries(e.response.data.result).forEach(
                    ([key, value]) => setFieldError(key, value[0])

                );

            }
            setLoading(false);
        });
    };

    const validationSchema = Yup.object().shape({
        parent_id: Yup.number().required('Parent Category is required'), // Add validation for parent_id
    });

    return (
        <div>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={state}
                validationSchema={validationSchema}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>

                        <Grid container spacing={6}>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <TextField
                                    type="text"
                                    name="english_name"
                                    id="english_name"
                                    value={values.english_name || ""}
                                    onChange={handleChange}
                                    label="Name In English"
                                    helperText={touched.english_name && errors.english_name}
                                    error={Boolean(touched.english_name && errors.english_name)}
                                    fullWidth
                                />

                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <TextField
                                    type="text"
                                    name="arabic_name"
                                    id="arabic_name"
                                    value={values.arabic_name || ""}
                                    onChange={handleChange}
                                    label="Name In Arabic"
                                    helperText={touched.arabic_name && errors.arabic_name}
                                    error={Boolean(touched.arabic_name && errors.arabic_name)}
                                    fullWidth
                                />

                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                                <Autocomplete
                                    options={categories}
                                    disableClearable
                                    defaultValue={state.parent}
                                    getOptionLabel={(option) => option.english_name}
                                    onChange={(event, newValue) => {
                                        setFieldValue('parent_id', newValue.id);
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    id="controllable-parent-state"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Parent Category"
                                            name="parent_category"
                                            value={state.parent_name || ""}
                                            variant="outlined"
                                            onChange={handleChange}
                                            helperText={touched.parent_id && errors.parent_id}
                                            error={Boolean(touched.parent_id && errors.parent_id)}
                                            fullWidth
                                        />
                                    )}
                                />

                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <FileInput
                                    url={values.english_image}
                                    handleChange={handleFileChange}
                                    fileName="english_image"
                                    label="English Image"
                                    helperText={touched.english_image && errors.english_image}
                                    error={Boolean(touched.english_image && errors.english_image)}
                                />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <FileInput
                                    url={values.arabic_image}
                                    handleChange={handleFileChange}
                                    fileName="arabic_image"
                                    label="Arabic Image"
                                    helperText={touched.arabic_image && errors.arabic_image}
                                    error={Boolean(touched.arabic_image && errors.arabic_image)}
                                />
                            </Grid>

                        </Grid>

                        <LoadingButton
                            type="submit"
                            color="primary"
                            loading={loading}
                            variant="contained"
                            sx={{ my: 4 }}
                        >
                            Submit
                        </LoadingButton>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default SubCategoryForm;
