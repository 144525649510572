import { LoadingButton } from "@mui/lab";
import { Grid, TextField } from "@mui/material";
import { Formik } from "formik";
import useSnackbar from "hooks/useSnackbar";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { cmsList } from "services/api/cms";
import { setCmsList } from "store/cms/cms.action";
import FileInput from "ui-component/FileInput";
import QuillTextEditorWrapper from "ui-component/QuillTextEditorWrapper";

const CmsForm = ({ handleSubmit, tableData, id }) => {
  const [state, setState] = useState({ ...tableData, password: "" });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();

  const handleInputChange = (keyLabel, value) => {
    setState({ ...state, [keyLabel]: value });
  };
  const { cmsId } = useParams();

  const handleFormSubmit = async (values, { setFieldError }) => {
    setLoading(true);
    await handleSubmit({
      ...values,
      english_content: state.english_content,
      arabic_content: state.arabic_content,
    })
      .then((res) => {
        openSnackbar("success", res.data.message);
        cmsList().then((res) => {
          dispatch(setCmsList(res.data.result.data));
        });
        setLoading(false);
        navigate(`/cms/${id}`);
      })
      .catch((e) => {
        if (e.response.status == 422) {
          Object.entries(e.response.data.result).forEach(([key, value]) =>
            setFieldError(key, value[0])
          );
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <Formik onSubmit={handleFormSubmit} initialValues={state}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={6}>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="english_title"
                  id="english_title"
                  value={values.english_title || ""}
                  onChange={handleChange}
                  label="Title In English"
                  helperText={touched.english_title && errors.english_title}
                  error={Boolean(touched.english_title && errors.english_title)}
                  fullWidth
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="arabic_title"
                  id="arabic_title"
                  value={values.arabic_title || ""}
                  onChange={handleChange}
                  label="Title In Arabic"
                  helperText={touched.arabic_title && errors.arabic_title}
                  error={Boolean(touched.arabic_title && errors.arabic_title)}
                  fullWidth
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <QuillTextEditorWrapper
                  text={values.english_content}
                  changeHandler={handleInputChange}
                  keyLabel="english_content"
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <QuillTextEditorWrapper
                  text={values.arabic_content}
                  changeHandler={handleInputChange}
                  keyLabel="arabic_content"
                />
              </Grid>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
              {cmsId == 1 ? (
                <FileInput
                  url={values.image}
                  handleChange={handleInputChange}
                  fileName="file"
                  label="image"
                  helperText={touched.file && errors.file}
                  error={Boolean(touched.file && errors.file)}
                />
              ) : (
                <p></p>
              )}
            </Grid>

            <LoadingButton
              type="submit"
              color="primary"
              loading={loading}
              variant="contained"
              sx={{ my: 4 }}
            >
              Submit
            </LoadingButton>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CmsForm;
