import CONTACTUS_ACTION_TYPES from './contactUs.types';

export const CONTACTUS_INITIAL_STATE = {
  contactUsLists: [],
};

export const ContactUsReducer = (state = CONTACTUS_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CONTACTUS_ACTION_TYPES.LOAD_CONTACTUS: {
      return { ...state, contactUsLists: payload };
    }
    default:
      return state;
  }
};
