import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/helper";
import Breadcrumb from "ui-component/Breadcrumb";
import SimpleCard from "ui-component/SimpleCard";
import { selectContactUs } from "store/contactUs/contactUs.selector";
import { contactUsList, contactUsUpdate } from "services/api/contact-us";
import { setContactUsList } from "store/contactUs/contactUs.action";
import ContactUsForm from "./ContactUsForm";
import { useEffect, useState } from "react";
import Loader from "ui-component/Loader";

const ContactUs = () => {
  const title = "Contact Us";

  const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }));

  const dispatch = useDispatch();
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);

    try {
      contactUsList(1).then((res) => {
        dispatch(setContactUsList(res.data.result));
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const contactUsState = useSelector(selectContactUs);
  console.log(contactUsState);
  const handleSubmit = (state) => {
    console.log(state);

    const formData = {
      email: state.email,
      phone_no: state.phone_no,
      instagram: state.instagram,
      x: state.x,
      website: state.website,
      location: state.location,
      location_url: state.location_url,
    };

    return contactUsUpdate(1, formData);
  };

  return (
    <Container>
      <Box className="breadcrumb">
        <Breadcrumb
          routeSegments={[
            { name: capitalizeFirstLetter(title), path: "/" + "contact-us" },
            { name: "Contact Us Update" },
          ]}
        />
      </Box>
      <SimpleCard title="Contact Us Form">
        {loading ? (
          <Loader />
        ) : (
          <ContactUsForm
            handleSubmit={handleSubmit}
            tableData={{ ...contactUsState }}
          />
        )}
      </SimpleCard>
    </Container>
  );
};

export default ContactUs;
