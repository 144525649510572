import { createSelector } from "reselect";

const selectContactUsReducer = (state) => {
  return state.contactUs.contactUsLists
};

export const selectContactUs = createSelector(
  [selectContactUsReducer],
  (state) => {
    return state
  }
);