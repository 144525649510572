// assets
import InfoIcon from '@mui/icons-material/Info';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import PolicyIcon from '@mui/icons-material/Policy';
// ==============================|| JOB WORK MENU ITEMS ||============================== //
import ContactsIcon from '@mui/icons-material/Contacts';
const cmsManagement = {
    id: 'cms-management',
    title: 'CMS Management',
    type: 'group',
    children: [
        {
            id: 'about-us',
            title: 'About Us',
            type: 'item',
            url: '/cms/1',
            icon: InfoIcon,
            breadcrumbs: false
        },
        {
            id: 'terms-and-conditios',
            title: 'Terms And Conditions',
            type: 'item',
            url: '/cms/2',
            icon: PrivacyTipIcon,
            breadcrumbs: false
        },
        {
            id: 'privacy-policy',
            title: 'Privacy Policy',
            type: 'item',
            url: '/cms/3',
            icon: PolicyIcon,
            breadcrumbs: false
        },
        {
            id: 'contact-us',
            title: 'Contact Us',
            type: 'item',
            url: '/contact-us',
            icon: ContactsIcon,
            breadcrumbs: false
        },
        
                
    ]
};

export default cmsManagement;
