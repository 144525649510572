import { Avatar, Box, Button, IconButton, styled } from "@mui/material";
import useAlert from "hooks/useAlert";
import useSnackbar from "hooks/useSnackbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Datatable from "ui-component/tables/Datatable";
import { capitalizeFirstLetter, isObjectEmpty } from "utils/helper";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  productDelete,
  productList,
  productUpdateStatus,
} from "services/api/product";
import { setProductList } from "store/product/product.action";
import { selectProduct } from "store/product/product.selector";
import Breadcrumb from "ui-component/Breadcrumb";
import {
  userProductViewList,
  userProductViewDelete,
} from "services/api/user-product-view";
import { setUserProductViewList } from "store/userProductView/userProductView.action";
import { selectUserProductView } from "store/userProductView/userProductView.selector";

const UserProductView = () => {
  const title = "User Product Request";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [operation, setOperation] = useState(false);

  const { updateState } = useAlert();

  useEffect(() => {
    setLoading(true);
    try {
      userProductViewList().then((res) => {
        console.log(res.data.result.data);
        dispatch(setUserProductViewList(res.data.result.data));
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [operation]);

  const handleDelete = (event, row) => {
    updateState("Color Delete", () => deleteHandler(row["rowData"][0]));
  };

  const deleteHandler = (id) => {
    setLoading(true);
    try {
      userProductViewDelete(id).then((res) => {
        setOperation(!operation);
      });
    } catch (error) {}
  };

  const handleView = (event, row) => {
    const url = "/products/view/" + row.rowData[5];
    navigate(url);
  };
  const userProductViews = useSelector(selectUserProductView);

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "Sr.no.",
      options: {
        display: true,
        download: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: "product_image",
      label: "Screenshot",
      options: {
        customBodyRender: (valueData, tableMeta) => {
          return (
            <a href={valueData} target="_blank">
              <Avatar
                alt="Product image"
                src={valueData}
                variant="square"
                sx={{ width: 50, height: 50 }}
              />
            </a>
          );
        },
        filter: false,
      },
    },
    {
      name: "Name",
      options: {
        display: true,
        download: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "Remark",
      options: {
        // display: true,
        // download: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: "Phone No.",
    },
    {
      name: "product_id",
      options: {
        display: false,
      },
    },
    {
      name: "Product",
    },
    {
      name: "created_at",
      label: "Requested Date",
    },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {/* <IconButton onClick={(e) => { handleView(e, tableMeta)}}>
                                <RemoveRedEyeIcon color="primary" />
                            </IconButton> */}
              <IconButton
                onClick={(e) => {
                  handleDelete(e, tableMeta);
                }}
              >
                <DeleteIcon color="primary" />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const data = isObjectEmpty(userProductViews)
    ? []
    : userProductViews.map((item, index) => {
        return [
          item.id,
          (index += 1),
          item.image,
          item.name,
          item.remark ?? "-",
          item.phone_no,
          item.product.id,
          item.product.english_title,
          item.created_at,
        ];
      });
  const options = {
    filterType: "textField",
    fixedHeader: true,
    tableBodyHeight: "400px",
  };

  return (
    <Datatable
      title={title}
      data={data}
      columns={columns}
      options={options}
      isLoading={loading}
      addHandler={() => navigate("/user-product-request/create")}
    />
  );
};

export default UserProductView;
